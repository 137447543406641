import { defineStore } from 'pinia'
import { normalizePrice } from 'lib/price'
import { updateRouteQuery } from '~/lib/filters'
import { type FilterGroup, type FilterMenuKeys, type FilterRules, convertConfigToFilters, includeFilter, normalizeSidebarFiltersKeys } from '~/types/models/filter.model'
import type { FilterClickSource } from '~/types/gtm'

export const useFilterStore = defineStore('FilterStore', () => {
  const allFilters = shallowRef<FilterGroup[]>(convertConfigToFilters())

  /** These are the facets that are available to the user depending on the page / category they are on */
  const allFacets = shallowRef<FilterGroup[]>([])

  /** 'subsetFacets' represents the facets that remain available to the user after applying filters to 'allFacets' */
  const subsetFacets = ref<FilterGroup[] | undefined>(undefined)

  /** These are the rules that are applied to the filter menu. They come from the category or page in Storyblok */
  const filterRules = shallowRef<FilterRules[]>([])
  const maxPriceRange = ref<number | undefined | null>(undefined)

  const filterMenuState = computed<FilterGroup[]>(() => {
    const mappedFilters = allFilters.value.map((_filterGroup) => {
      if (maxPriceRange.value && _filterGroup.id === 'price' && _filterGroup.metadata.inputType === 'range') {
        const priceToItem = _filterGroup.items.find(i => i.id === 'price_to')

        if (priceToItem && priceToItem.metadata) {
          priceToItem.metadata.defaultValue = maxPriceRange.value

          if (typeof priceToItem.value === 'number' && priceToItem.value > maxPriceRange.value)
            priceToItem.value = maxPriceRange.value
        }
      }

      return {
        ..._filterGroup,
        metadata: {
          ..._filterGroup.metadata,
          hidden: !includeFilter(filterRules.value, _filterGroup.id),
        },
      }
    })

    const mappedFacets = cloneAllFacets().map((_filterGroup) => {
      const filterGroup = {
        ..._filterGroup,
        metadata: {
          ..._filterGroup.metadata,
          hidden: !includeFilter(filterRules.value, _filterGroup.id),
        },
      }

      if (!subsetFacets.value)
        return filterGroup

      const modifier = subsetFacets.value?.find(f => f.id === filterGroup.id)

      if (!modifier) {
        return {
          ...filterGroup,
          disabled: true, // TODO: Implement disabled in UI on group level
        }
      }

      return {
        ...filterGroup,
        items: filterGroup.items.map((item) => {
          const modifierItem = modifier.items.find(i => i.id === item.id)

          if (!modifierItem) {
            return {
              ...item,
              disabled: true,
            }
          }

          return {
            ...item,
            ...modifierItem,
          }
        }),
      }
    })

    return [...mappedFilters, ...mappedFacets]
  })

  const sidebarFiltersState = computed<FilterGroup[]>(() => {
    const StoryblokSidebarFilters = normalizeSidebarFiltersKeys()
    const filteredSidebarFilters = filterMenuState.value.filter(item => StoryblokSidebarFilters.includes(item.id))
      .toSorted((a, b) => {
        return StoryblokSidebarFilters.indexOf(a.id) - StoryblokSidebarFilters.indexOf(b.id)
      })
    return filteredSidebarFilters
  })

  const selectedFilters = computed<FilterGroup[]>(() => {
    return sidebarFiltersState.value.reduce((acc, filterGroup) => {
      const selectedItems = filterGroup.items.filter(item => Boolean(item.value) && !(item.metadata?.defaultValue === item.value))

      if (selectedItems.length) {
        acc.push({
          ...filterGroup,
          items: selectedItems,
        })
      }

      return acc
    }, [] as FilterGroup[])
  })

  const selectedFilterGroupKeys = computed<FilterMenuKeys[]>(() =>
    selectedFilters.value.map(({ id }) => id),
  )
  const activeFiltersCount = computed(() => selectedFilterGroupKeys.value.length)

  const filterBarHidden = computed(() => filterRules.value.includes('no-filters'))

  // Methods
  function setFilterRules(rules: FilterRules[]) {
    filterRules.value = rules
  }

  function setAllFacets(filterGroups: FilterGroup[]) {
    allFacets.value = filterGroups
  }

  function cloneAllFacets() {
    return JSON.parse(JSON.stringify(allFacets.value)) as FilterGroup[]
  }

  function setSubsetFacets(filterGroups?: FilterGroup[]) {
    subsetFacets.value = filterGroups
  }

  function setMaxPriceRange(maxPrice: number) {
    maxPriceRange.value = normalizePrice(maxPrice)
    clearFilterGroup('price')
  }

  function applyFilters(filters: FilterGroup[]) {
    for (const filter of filters) {
      const filterGroup = allFilters.value.find(f => f.id === filter.id)

      if (!filterGroup)
        continue

      for (const item of filter.items) {
        const filterItem = filterGroup.items.find(i => i.id === item.id)
        if (filterItem) {
          const parsedValue = (filterGroup.metadata.inputType === 'range' && typeof item.value === 'string') ? Number.parseInt(item.value) : item.value
          filterItem.value = parsedValue
        }
      }
    }
  }

  function reset() {
    allFilters.value = convertConfigToFilters()
    subsetFacets.value = undefined
  }

  function clearFilterGroup(id: string): FilterGroup[] {
    const facetStore = useFacetStore()
    const config = facetStore.DYNAMIC_FACET_CONFIG.find(facetConfig => facetConfig.key === id)

    if (!config)
      return sidebarFiltersState.value

    const type = config.type

    const filterGroup = ((): FilterGroup | undefined => {
      if (type === 'filter')
        return allFilters.value.find(f => f.id === id)

      if (type === 'facet') {
        if (!subsetFacets.value)
          subsetFacets.value = cloneAllFacets()

        return subsetFacets.value.find(f => f.id === id)
      }

      return undefined
    })()

    if (!filterGroup)
      return sidebarFiltersState.value

    filterGroup.items.forEach((item) => {
      const configItem = config.items?.find(i => i.id === item.id)
      item.value = configItem?.value ?? undefined
    })

    return sidebarFiltersState.value
  }

  /**
   * @param id - Id of the Facet or Filter
   * @param itemId - Id of the item within the Facet or Filter
   * @param value - Value to set the item to
   */
  function applyFilterItem(id: string, itemId: string, value: any): FilterGroup[] {
    const facetStore = useFacetStore()
    const configGroup = facetStore.DYNAMIC_FACET_CONFIG.find(facetConfig => facetConfig.key === id)

    if (!configGroup)
      return sidebarFiltersState.value

    const type = configGroup.type

    const filterGroup = ((): FilterGroup | undefined => {
      if (type === 'filter') {
        const filter = allFilters.value.find(f => f.id === id)

        return filter
      }

      if (type === 'facet') {
        if (!subsetFacets.value)
          subsetFacets.value = cloneAllFacets()

        return subsetFacets.value.find(f => f.id === id)
      }

      return undefined
    })()

    if (!filterGroup)
      return sidebarFiltersState.value

    const item = filterGroup.items.find(i => i.id === itemId)

    if (!item)
      return sidebarFiltersState.value

    item.value = value

    return sidebarFiltersState.value
  }

  function sendFilterClickEvent(id: string, clickSource: FilterClickSource) {
    const selectedFilterValues = selectedFilters.value.find(filter => filter.id === id)?.items.map(item => item.id) || []
    const selectedFiltersMap = selectedFilters.value.map(filter => ({ id: filter.id, values: filter.items.map(item => item.id) }))

    useGTM().pushFilterClick({
      filterId: id,
      filterValue: selectedFilterValues,
      activeFilters: selectedFiltersMap,
      clickSource,
    })
  }

  function updateRouteQueryFromState() {
    updateRouteQuery(selectedFilters.value)
  }

  return {
    // State
    allFilters,
    allFacets,
    subsetFacets,
    maxPriceRange,

    // Computed
    selectedFilters,
    filterMenuState,
    sidebarFiltersState,
    filterBarHidden,
    selectedFilterGroupKeys,
    activeFiltersCount,

    // Methods - Mutations
    setAllFacets,
    setSubsetFacets,
    setFilterRules,
    setMaxPriceRange,
    applyFilters,

    // Methods
    reset,
    applyFilterItem,
    clearFilterGroup,
    updateRouteQueryFromState,
    sendFilterClickEvent,
  }
})
